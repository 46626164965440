const URLS = {
  HOST_URL: "https://api.yajya.com",
  // HOST_URL: "http://localhost:3001",
  SUFFIX_URL: "/v1",
  LOGIN: "/auth/admin/login",
  BANNER: "/banner",
  CATEGORY: "/planCategory",
  SUB_CATEGORY: "/plan",
  PANDITS: "/user/profiles",
  WALLETTRANSACTION: "/walletTransaction",
  WALLET_PAYOUT: "/walletTransaction/payout",
  SEND_NOTIFICATION: "/notification/send",
  NOTIFICATION: "/notification",
  SAMAGRI: "/samgiri",
  LANGUAGE: "/language",
  DASHBOARD: "/dashboard",
  CITY: "/city",
  CITY_STATE: "/city/getStatesCities",
  ASSIGNMENT_TRACKING: "/assignmentTracking",
  RELEVANT_PANDIT: "/assignmentTracking/getRelevantPandit",
  DELETE_PANDIT_ONBOARD: "/auth/user/delete-account",
  PANDIT_ONBOARD: "/panditOnBoard",
  VERIFY_PANDIT_DOCUMENT: "/user/documentVerify",
};
export default URLS;
