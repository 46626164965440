import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSANGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let messaging;

isSupported()
  .then((supported) => {
    if (supported) {
      messaging = getMessaging(app);
      console.log("Firebase Messaging is initialized");
    } else {
      console.warn("Firebase Messaging is not supported in this browser.");
    }
  })
  .catch((error) => {
    console.error("Error checking Firebase Messaging support:", error);
  });

export { messaging };
