import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "subComponents/Tables/DataTable";
import { useForm, FormProvider } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import userTableData from "./data/userTableData";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useFilter from "utility/useFilter";
import { useDebounce } from "@uidotdev/usehooks";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useLazyGetPanditsQuery } from "../../redux/api/panditsApi";
import { useDeletePanditOnboardMutation } from "../../redux/api/panditOnboardApi";

function User() {
  const methods = useForm();
  const [getPandits] = useLazyGetPanditsQuery();
  const [page, setPage] = useState(1);
  const [userList, setUserList] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const [refresh, setRefresh] = useState(false);

  const [deletePanditOnboard, { isLoading: isDeletePanditOnboardLoading }] =
    useDeletePanditOnboardMutation();

  const handleToggleChange = async (userId) => {
    console.log(userId, "userId");
    try {
      await deletePanditOnboard(userId);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error while toggling isActive state:", error);
    }
  };

  const { filters, setFilters } = useFilter();

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      role: "user",
      firstName: debouncedSearchTerm,
      page,
    }));
  }, [debouncedSearchTerm, page]);

  const handleChange = (e, p) => {
    setPage(p);
  };

  const fetchUsersData = async () => {
    try {
      const response = await getPandits(filters);
      if (response) {
        setUserList(response?.data?.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchUsersData();
  }, [filters, page, refresh]);

  const { columns, rows } = userTableData(userList?.results, handleToggleChange);

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, name: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  console.log(userList?.results, "setUserList");

  return (
    <FormProvider {...methods}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
                <input
                  className="text-sm p-2 text-gray-700 rounded-md w-56"
                  type="text"
                  placeholder="Search By User Name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={userList?.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </FormProvider>
  );
}

export default User;
