import * as React from "react";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import MDAvatar from "components/MDAvatar";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import { formatEstimReachTime } from "utility/extractDateTime";

function AssignPanditHistory({ assignPanditData }) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const [panditExpanded, setPanditExpanded] = React.useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePanditChange = (panel) => (event, isExpanded) => {
    setPanditExpanded((prevState) => ({
      ...prevState,
      [panel]: isExpanded ? panel : false,
    }));
  };

  const handleViewAllClick = () => {
    navigate("/bookings");
  };

  return (
    <Card
      style={{
        boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)",
      }}
      sx={{ height: "500px", padding: "20px" }}
    >
      <MDBox className="flex justify-between">
        <MDTypography variant="h6" fontWeight="medium">
          Assign Pandit History
        </MDTypography>
        <Button onClick={handleViewAllClick} variant="submit">
          View All
        </Button>
      </MDBox>
      <div className="mt-4 overflow-y-scroll">
        {assignPanditData &&
          (assignPanditData || [])?.map((data, index) => {
            // Formatting date and time
            const { formattedDate, formattedTime } = formatEstimReachTime(
              data?.order?.estimReachTime
            );

            return (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography
                    component="div"
                    sx={{ width: "33%", flexShrink: 0, marginRight: "65px" }}
                  >
                    <h1 className="text-xs text-gray-500 w-44">
                      OrderId <span className="text-black text-xs">{data?.order?._id}</span>
                    </h1>
                  </Typography>
                  <Typography component="div">
                    <h1 className="text-xs text-gray-500 w-20">
                      Status{" "}
                      <span
                        className={`text-xs ${
                          data.order?.panditUserId ? "text-green-700" : "text-orange-700"
                        }`}
                      >
                        {data.order?.panditUserId ? "Accepted" : "Pending"}
                      </span>
                    </h1>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    <h1 className="text-sm">
                      UserName :{" "}
                      <span className="text-gray-500 ml-1">
                        {data?.order?.orderedUser[0]?.firstName}&nbsp;
                        {data?.order?.orderedUser[0]?.lastName}
                      </span>
                    </h1>
                  </Typography>
                  <Typography component="div">
                    <h1 className="text-sm">
                      Contact :{" "}
                      <span className="text-gray-500 ml-1">
                        {data?.order?.orderedUser[0]?.phone}
                      </span>
                    </h1>
                  </Typography>
                  <Typography component="div">
                    <h1 className="text-sm">
                      Yajya Name :{" "}
                      <span className="text-gray-500 ml-1">
                        {data?.order?.plan[0]?.name || "-"}
                      </span>
                    </h1>
                  </Typography>
                  <Typography component="div">
                    <div className="text-sm">
                      Preferred Language:{" "}
                      <span className="text-gray-500 ml-1">
                        {data?.order?.languagePref?.length > 0
                          ? data.order.languagePref.map((lang, index) => (
                              <span key={index}>
                                {lang.name}
                                {index < data.order.languagePref.length - 1 && ", "}
                              </span>
                            ))
                          : "-"}
                      </span>
                    </div>
                  </Typography>
                  <Typography component="div">
                    <h1 className="text-sm">
                      Amount : <span className="text-gray-500 ml-1">{data?.order?.amount}</span>
                    </h1>
                  </Typography>
                  <Typography component="div">
                    <h1 className="text-sm">
                      Location :{" "}
                      <span className="text-xs text-gray-500">
                        {data.order?.address[0]?.address}, {data.order?.address[0]?.landMark}
                      </span>
                    </h1>
                  </Typography>
                  <Typography component="div">
                    <h1 className="text-sm">
                      Date : <span className="text-gray-500 ml-1">{formattedDate || "-"}</span>
                    </h1>
                  </Typography>
                  <Typography component="div">
                    <h1 className="text-sm">
                      Time : <span className="text-gray-500 ml-1">{formattedTime || "-"}</span>
                    </h1>
                  </Typography>
                  <div className="mt-2">
                    <Accordion
                      key={`pandit-${index}`}
                      expanded={panditExpanded[`pandit${index}`] === `pandit${index}`}
                      onChange={handlePanditChange(`pandit${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`pandit${index}bh-content`}
                        id={`pandit${index}bh-header`}
                      >
                        <Typography
                          component="div"
                          sx={{ width: "33%", flexShrink: 0, marginRight: "30px" }}
                        >
                          <h1 className="text-xs text-gray-500">Pandit Details</h1>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {(data?.pandits || [])?.map((pandit, panditIndex) => (
                          <div key={panditIndex} className="">
                            <div className="flex justify-between">
                              <MDBox display="flex" alignItems="center" lineHeight={1}>
                                <MDAvatar
                                  src={pandit?.panditUserId?.profileImage}
                                  name={pandit?.panditUserId?.firstName}
                                  size="sm"
                                />
                                <MDBox
                                  display="flex"
                                  justifyContent="space-between"
                                  lineHeight={1}
                                  ml={2}
                                >
                                  <div>
                                    <MDTypography
                                      display="block"
                                      variant="button"
                                      fontWeight="medium"
                                    >
                                      {pandit?.panditUserId?.firstName}&nbsp;{" "}
                                      {pandit?.panditUserId?.lastName}
                                    </MDTypography>
                                    <MDTypography variant="caption">
                                      +{pandit?.panditUserId?.dialCode}&nbsp;{" "}
                                      {pandit?.panditUserId?.phone}
                                    </MDTypography>
                                  </div>
                                </MDBox>
                              </MDBox>
                              <h2
                                className={`text-sm ${
                                  pandit?.status === "completed"
                                    ? "text-green-700"
                                    : pandit?.status === "cancelled"
                                    ? "text-red-700"
                                    : pandit?.status === "pending"
                                    ? "text-orange-700"
                                    : pandit?.status === "accepted"
                                    ? "text-green-700"
                                    : "text-black"
                                }`}
                              >
                                {pandit?.status}
                              </h2>
                            </div>
                            <hr className="my-2 text-black" />
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </Card>
  );
}

export default AssignPanditHistory;
