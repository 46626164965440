import jsPDF from "jspdf";
import "jspdf-autotable";
import image1 from "../../../assets/images/logo.png";
import image2 from "../../../assets/images/Asset 12@300x.png";

export const handleDownloadInvoice = (item) => {
  const doc = new jsPDF();

  const colors = {
    blue: "#FEE4D1",
    white: "#393535",
    lightGray: "#F4F4F4",
    black: "#000000",
    darkGray: "#555555",
  };

  doc.setFillColor(colors.blue);
  doc.rect(0, 0, 210, 40, "F");

  const logoWidth = 20;
  const logoHeight = 20;
  const logoX = 10;
  const logoY = 5;
  doc.addImage(image1, "PNG", logoX, logoY, logoWidth, logoHeight);

  const titleY = logoY + logoHeight + 5;
  doc.setFontSize(18);
  doc.setTextColor(colors.white);
  doc.text("Invoice", logoX + logoWidth / 2, titleY, null, null, "center");

  doc.setFontSize(10);
  doc.setTextColor(colors.white);
  const companyInfoX = 200;
  doc.text("Yajya Max Consultant", companyInfoX, 15, null, null, "right");
  doc.text("2, Nilkanth Villa", companyInfoX, 20, null, null, "right");
  doc.text("DPS-Bopal Canal Road", companyInfoX, 25, null, null, "right");
  doc.text("Bopal, Ahmedabad - 380048", companyInfoX, 30, null, null, "right");

  const detailsY = 55;
  doc.setFontSize(12);
  doc.setTextColor(colors.black);
  doc.text("BILL TO:", 15, detailsY);
  doc.setFontSize(10);
  doc.text(
    `${item?.order?.orderedUser?.firstName || "-"} ${item?.order?.orderedUser?.lastName || "-"}`,
    15,
    detailsY + 6
  );
  doc.text(`${item?.order?.orderedUser?.phone || "-"}`, 15, detailsY + 12);

  const invoiceDetailsX = 135;
  const valueX = 160;
  const lineSpacing = 8;
  doc.setFontSize(12);
  doc.text("ObjectId:", invoiceDetailsX, detailsY);
  doc.setFontSize(10);
  doc.text(`${item?.order?._id || "000001"}`, valueX, detailsY);

  doc.setFontSize(12);
  doc.text("Date:", invoiceDetailsX, detailsY + lineSpacing);
  doc.setFontSize(10);
  doc.text(`${new Date().toLocaleDateString()}`, valueX, detailsY + lineSpacing);

  doc.setFontSize(12);
  doc.text("Due Date:", invoiceDetailsX, detailsY + 2 * lineSpacing);
  doc.setFontSize(10);
  doc.text("12/31/20", valueX, detailsY + 2 * lineSpacing);

  const dividerY = detailsY + 2 * lineSpacing + 10;
  doc.setDrawColor(200, 200, 200);
  doc.line(0, dividerY, 230, dividerY);

  const tableStartY = dividerY + 14;
  const tableHeaders = ["Sr. No", "Yajya", "Quantity", "Price"];
  const tableRows = [
    [
      "1",
      [
        item?.order?.plan?.name || "-",
        `Date : ${
          item?.order?.estimReachTime
            ? new Date(item?.order?.estimReachTime).toLocaleDateString()
            : "N/A"
        }`,

        `Time : ${
          item?.order?.estimReachTime
            ? new Date(item?.order?.estimReachTime).toLocaleTimeString()
            : "N/A"
        }`,
      ]
        .filter(Boolean)
        .join("\n\n"),
      "1",
      `${item?.order?.amount || "0.00"} /-`,
    ],
  ];

  doc.autoTable({
    startY: tableStartY,
    head: [tableHeaders],
    body: tableRows,
    theme: "plain",
    bodyStyles: {
      textColor: colors.black,
      fontSize: 10,
    },
  });

  const totalY = doc.lastAutoTable.finalY + 12;
  const pageWidth = 210;
  const notesSectionWidth = pageWidth * 0.75;
  const totalSectionWidth = pageWidth * 0.25;
  const sectionHeight = 30;

  doc.setFillColor(colors.lightGray);
  doc.rect(0, totalY, notesSectionWidth, sectionHeight, "F");

  doc.setFontSize(10);
  doc.setTextColor(colors.darkGray);
  doc.text("NOTES:", 10, totalY + 10);
  const notesText =
    "Thank you for choosing Yajya! Explore spiritual services, rituals, and more at Yajya.com. Need help? Our team is here for you. Visit Yajya.com for peace and positivity!";
  doc.text(doc.splitTextToSize(notesText, notesSectionWidth - 15), 10, totalY + 15);

  doc.setFillColor(colors.blue);
  doc.rect(notesSectionWidth, totalY, totalSectionWidth, sectionHeight, "F");

  doc.setFontSize(8);
  doc.setTextColor(colors.white);
  doc.text("TOTAL", notesSectionWidth + 32, totalY + 10);

  doc.setFontSize(17);
  doc.text(
    `INR ${item?.order?.amount || "0.00"}`,
    notesSectionWidth + totalSectionWidth / 1.8,
    totalY + 18,
    null,
    null,
    "center"
  );

  const footerY = totalY + sectionHeight + 15;
  doc.setFontSize(10);
  doc.setTextColor(colors.darkGray);
  doc.text(
    "Thank you for using Yajya! Discover more spiritual services at Yajya.com.",
    105,
    footerY,
    null,
    null,
    "center"
  );
  doc.addImage(image1, "PNG", 100, footerY + 5, 10, 10);

  const fileName = `invoice-${item?.order?._id || "unknown"}.pdf`;
  doc.save(fileName);
};
