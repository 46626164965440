import React from "react";
import { Grid, Button } from "@mui/material";
import { exportToExcel } from "./actions";
import { exportToCSV } from "./actions";
import { printData } from "./actions";

const Filter = ({ data }) => {
  const handleActionClick = (action) => {
    switch (action) {
      case "Excel":
        exportToExcel(data);
        break;
      case "CSV":
        exportToCSV(data);
        break;
      case "Print":
        printData(data);
        break;
      default:
        toast.error("Unknown action");
    }
  };

  return (
    <Grid container mt={0.5} spacing={2}>
      {["Excel", "CSV", "Print"].map((action) => (
        <Grid item key={action}>
          <Button
            variant="outlined"
            onClick={() => handleActionClick(action)}
            sx={{
              borderRadius: "8px",
              color: "#FD8D4C",
              borderColor: "#FD8D4C",
              "&:hover": {
                backgroundColor: "#FD8D4C",
                color: "#FFF",
              },
            }}
          >
            {action}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default Filter;
