// dashboardReducers.js
const getDashboard = (state, { payload }) => {
  state.dashboard = payload;
};

const refreshDashboard = (state) => {
  console.log("🚀 ~ refreshDashboard ~ state:", state?.refreshDashboard);

  // If refreshDashboard is undefined, initialize it to true, otherwise toggle it
  state.refreshDashboard = state.refreshDashboard === undefined ? true : !state.refreshDashboard;
};

export { getDashboard, refreshDashboard };
