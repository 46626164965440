import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useForm, FormProvider } from "react-hook-form";
import { RHFSelect } from "../../../hook-form/RHFSelect";
import { Button, Box } from "@mui/material";
import Cards from "./card";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddSamagriMutation, useUpdateSamagriMutation } from "../../../redux/api/samagriApi";
import { useLazyGetSubCategoryQuery } from "../../../redux/api/subCategoryApi";
import toast from "react-hot-toast";
import useFetchCategory from "../../../utility/useFetchCategory";
import { useDispatch } from "react-redux";
import { customToastStyle } from "../../../redux/api";
import useFilter from "utility/useFilter";
import { useGetCategoryQuery } from "../../../redux/api/categoryApi";

const AddSamagri = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state || {};
  const [getSubCategory] = useLazyGetSubCategoryQuery();
  const [subCategoryData, setSubCategoryData] = useState([]);

  const [addSamagri] = useAddSamagriMutation();
  const [updateSamagri] = useUpdateSamagriMutation();
  const { filters } = useFilter();
  const { data: planData } = useGetCategoryQuery(filters);
  // const planOptions = useFetchCategory();
  const planOptions = planData?.data?.results?.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }));
  const methods = useForm({
    defaultValues: {
      planCategory: item?.planCategory?._id || "",
      plan: item?.plan?._id || "",
      panditSamgiri: item?.panditSamgiri || [],
      userSamgiri: item?.userSamgiri || [],
    },
  });
  const { watch, setValue } = methods;
  const planCategory = watch("planCategory");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryId = planCategory || item?.planCategory?._id;
        let response = await getSubCategory({ planCategory: categoryId });

        setSubCategoryData(response);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    if (item?.plan?._id || planCategory) {
      fetchData();
    }
  }, [planCategory, item?.planCategory?._id]);

  const subCategoryOptions = subCategoryData?.data?.data?.results?.map((subcategory) => ({
    label: subcategory.name,
    value: subcategory.id,
  }));

  useEffect(() => {
    if (item?.plan?._id && subCategoryOptions?.some((option) => option.value === item.plan.id)) {
      setValue("plan", item.plan.id);
    }
  }, [subCategoryData]);

  const [panditItems, setPanditItems] = useState(item?.panditSamgiri || []);
  const [userItems, setUserItems] = useState(item?.userSamgiri || []);

  const handleSamgiriItems = (newItems, existingItems, itemKey) => {
    const newSamgiriItems = newItems.filter(
      (newItem) => !existingItems.some((existingItem) => existingItem.name === newItem.name)
    );

    const removedSamgiriItems = existingItems.filter(
      (existingItem) => !newItems.some((newItem) => newItem.name === existingItem.name)
    );

    const result = {};
    if (newSamgiriItems.length > 0) {
      result[`new${itemKey}`] = newSamgiriItems;
    }
    if (removedSamgiriItems.length > 0) {
      result[`removed${itemKey}`] = removedSamgiriItems.map((item) => item.name);
    }

    return result;
  };

  const handleSubmitClick = async (data) => {
    let samagri = {
      planCategory: data.planCategory,
      plan: data.plan,
    };

    if (item?._id) {
      const panditSamgiriChanges = handleSamgiriItems(
        panditItems,
        item.panditSamgiri,
        "PanditSamgiri"
      );
      const userSamgiriChanges = handleSamgiriItems(userItems, item.userSamgiri, "UserSamgiri");

      samagri = {
        ...samagri,
        ...panditSamgiriChanges,
        ...userSamgiriChanges,
      };
    } else {
      // For add operation, just send the full list
      samagri = {
        ...samagri,
        panditSamgiri: panditItems,
        userSamgiri: userItems,
      };
    }

    const formData = new FormData();
    Object.entries(samagri).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      if (item?._id) {
        // Update operation
        const updateSamagriResponse = await updateSamagri({ id: item?._id, ...samagri });
        if (!updateSamagriResponse?.data?.success) {
          toast.error(`${updateSamagriResponse?.data?.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
        methods.reset({
          planCategory: "",
          plan: "",
        });
      } else {
        // Add operation
        const samagriResponse = await addSamagri({ ...samagri });
        if (!samagriResponse?.data?.success) {
          toast.error(`${samagriResponse?.data?.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
      }
      methods.reset();
    } catch (err) {
      console.error("Failed to add/update samagri:", err);
    }

    setPanditItems([]);
    setUserItems([]);
    navigate("/samagri");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitClick)}>
        <Card>
          <div className="flex justify-between w-96 space-x-4 m-4">
            <div className="mb-2 mr-2 w-56">
              <div className="flex">
                <h1 className="font-bold text-sm mb-1">Category</h1>
                &nbsp;<span style={{ color: "red" }}>*</span>
              </div>

              <RHFSelect
                name="planCategory"
                options={planOptions}
                required
                disabled={!!item?._id}
              />
            </div>
            <div className="mb-2 w-56">
              <div className="flex">
                <h1 className="font-bold text-sm mb-1">Yajya&apos;s</h1>
                &nbsp;<span style={{ color: "red" }}>*</span>
              </div>

              <RHFSelect name="plan" options={subCategoryOptions} required disabled={!!item?._id} />
            </div>
          </div>
        </Card>

        <div className="w-[100%] gap-10 grid grid-cols-2">
          <Cards
            title="Pandit"
            dataKey="panditSamgiri"
            items={panditItems}
            setItems={setPanditItems}
            isUpdate={!!item?.id}
          />
          <Cards
            title="User"
            dataKey="userSamgiri"
            items={userItems}
            setItems={setUserItems}
            isUpdate={!!item?.id}
          />
        </div>
        <div className="flex justify-center mt-5">
          <Button variant="submit" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddSamagri;
