import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "subComponents/Tables/DataTable";
import { useForm, FormProvider } from "react-hook-form";
import VerifyPandit from "./verifyDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import authorsTableData from "./data/authorsTableData";
import { useLazyGetPanditsQuery } from "../../redux/api/panditsApi";
import { getPanditsAction } from "../../redux/slices/panditsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { set } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import useFilter from "utility/useFilter";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDeletePanditOnboardMutation } from "../../redux/api/panditOnboardApi";

function Pandits() {
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [panditsData, setPanditsData] = useState([]);
  const [verifyDialogIndex, setVerifyDialogIndex] = useState(null);
  const [documents, setDocuments] = useState({});
  const [searchValue, setSearchValue] = useState();
  const debouncedSearchTerm = useDebounce(searchValue, 800);
  const { filters, setFilters } = useFilter();
  const [isDocumentVerified, setIsDocumentVerified] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [deletePanditOnboard, { isLoading: isDeletePanditOnboardLoading }] =
    useDeletePanditOnboardMutation();

  const handleToggleChange = async (panditId) => {
    console.log("handleToggleChange");
    console.log(panditId, "panditId");
    try {
      await deletePanditOnboard(panditId);
      console.log("API called for Pandit ID:", panditId);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error while toggling isActive state:", error);
    }
  };

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      role: "pandit",
      firstName: debouncedSearchTerm,
      page,
    }));
  }, [debouncedSearchTerm, page]);

  const handleChange = (e, p) => {
    setPage(p);
  };

  const fetchPanditsData = async () => {
    try {
      const response = await getPandits(filters);
      if (response) {
        setPanditsData(response?.data?.data?.results);
      }
    } catch (err) {}
  };
  useEffect(() => {
    fetchPanditsData();
  }, [filters, page, refresh]);

  const handleVerifyClick = (index) => {
    setVerifyDialogIndex(index);
    setDocuments({
      documentVerification: panditsData[index]?.documentVerification,
      isDocumentVerified: panditsData[index]?.isDocumentVerified,
    });
  };
  const { columns, rows } = authorsTableData(panditsData, handleVerifyClick, handleToggleChange);

  const [getPandits] = useLazyGetPanditsQuery();

  const handleCloseClick = () => {
    setVerifyDialogIndex(null);
    methods.reset();
  };

  const handleOnClick = () => {
    navigate("/pandits/add-pandit");
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-end">
        {" "}
        <Button onClick={handleOnClick} variant="addButton">
          Add Pandit
        </Button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Pandits
                </MDTypography>
                <input
                  className="text-sm p-2 text-gray-700 rounded-md w-56"
                  type="text"
                  placeholder="Search By Pandit Name"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {verifyDialogIndex !== null ? (
        <VerifyPandit
          id={panditsData[verifyDialogIndex]?.id}
          documents={documents}
          onClose={handleCloseClick}
          open={verifyDialogIndex !== null}
          setIsDocumentVerified={setIsDocumentVerified}
        />
      ) : (
        ""
      )}
      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={panditsData?.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </FormProvider>
  );
}

export default Pandits;
