import React from "react";
import { Card, Box, Typography } from "@mui/material";
import RHFTextField from "../../../hook-form/RHFTextField";
import { Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { Icon } from "@iconify/react";

const Cards = ({ title, dataKey, items, setItems, isUpdate }) => {
  const { getValues, setValue, control } = useFormContext();

  const handleAddClick = () => {
    const currentValues = getValues();
    const newItem = {
      name: currentValues[`${dataKey}Name`] || "",
      quantity: `${currentValues[`${dataKey}Quantity`] || ""} ${
        currentValues[`${dataKey}Unit`] || "unit"
      }`,
    };

    if (newItem.name && currentValues[`${dataKey}Quantity`]) {
      setItems((prevItems) => [...prevItems, newItem]);
      setValue(dataKey, [...items, newItem]);
    }
  };

  const handleDeleteClick = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    setValue(dataKey, updatedItems);
  };

  return (
    <Card sx={{ padding: "20px", marginTop: "20px" }}>
      <Typography variant="h6">
        {title} Samgiri &nbsp;<span style={{ color: "red" }}>*</span>
      </Typography>
      {items.map((item, index) => (
        <div key={index} className="flex items-center w-full justify-between mt-4">
          <div className="flex space-x-4">
            <Typography variant="listItems">{index + 1}.</Typography>
            <Typography variant="listItems">{item.name}</Typography>
            <Typography variant="listItems">{item.quantity}</Typography>
          </div>
          <Icon
            className="text-red-800 h-4"
            icon="ep:delete"
            onClick={() => handleDeleteClick(index)}
          />
        </div>
      ))}
      <div className="gap-5 mt-3 grid grid-cols-3">
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" fontSize={15}>
            Name
          </Typography>
          <RHFTextField
            name={`${dataKey}Name`}
            placeholder="Enter Name"
            type="text"
            fullWidth
            required={isUpdate}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" fontSize={15}>
            Quantity
          </Typography>
          <RHFTextField
            name={`${dataKey}Quantity`}
            placeholder="Enter Quantity"
            type="number"
            fullWidth
            required={isUpdate}
          />
        </Box>
        <Box sx={{ flex: 1, width: "100%", maxWidth: "200px", margin: "auto" }}>
          <Typography variant="subtitle1" fontSize={15}>
            Unit
          </Typography>
          <FormControl fullWidth>
            <Controller
              name={`${dataKey}Unit`}
              control={control}
              defaultValue="unit"
              render={({ field }) => (
                <Select
                  sx={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                  }}
                  {...field}
                  labelId={`${dataKey}Unit-label`}
                >
                  <MenuItem value="unit">unit</MenuItem>
                  <MenuItem value="kg">kg</MenuItem>
                  <MenuItem value="g">gm</MenuItem>
                  <MenuItem value="liters">liters</MenuItem>
                  <MenuItem value="ml">ml</MenuItem>
                  <MenuItem value="dozen">dozen</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </div>
      <div className="mt-4 flex justify-center">
        <Button type="button" onClick={handleAddClick} variant="submit">
          Add
        </Button>
      </div>
    </Card>
  );
};

export default Cards;
