import { apiSlice } from ".";
import URLS from "../constants/api";

export const panditOnboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addPanditOnboard: builder.mutation({
      query: (body) => ({
        url: `${URLS.PANDIT_ONBOARD}`,
        method: "POST",
        body,
      }),
      providesTags: ["PanditOnboard"],
    }),
    deletePanditOnboard: builder.mutation({
      query: (id) => ({
        url: `${URLS.DELETE_PANDIT_ONBOARD}?id=${id}`,
        method: "DELETE",
      }),

      invalidatesTags: ["PanditOnboard"],
    }),
  }),
});

export const { useAddPanditOnboardMutation, useDeletePanditOnboardMutation } =
  panditOnboardApiSlice;
