import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSubCategoryAction } from "../redux/slices/subCategorySlice";
import { useLazyGetSubCategoryQuery } from "../redux/api/subCategoryApi";
import useFetchData from "./useFetchData";

const useFetchSubCategory = () => {
  const [getSubCategory] = useLazyGetSubCategoryQuery();
  const fetchSubCategoryData = useFetchData(getSubCategory, getSubCategoryAction);
  const subCategoryData = useSelector((state) => state?.subCategory?.subCategory);

  const subCategoryOptions =
    subCategoryData?.results?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || [];

  useEffect(() => {
    if (!subCategoryData?.results || subCategoryData?.length === 0) {
      fetchSubCategoryData({ sortBy: "createdAt:desc" });
    }
  }, [subCategoryData]);

  return subCategoryOptions;
};

export default useFetchSubCategory;
