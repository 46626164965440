import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Button, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "subComponents/Tables/DataTable";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import languageTableData from "./data/languageTableData";
import AddLanguage from "./addLanguage";
import ConfirmDelete from "../../shared/deleteDialog";
import { useLazyGetLanguageQuery, useDeleteLanguageMutation } from "../../redux/api/languageApi";
import useFilter from "utility/useFilter";
import { useDebounce } from "@uidotdev/usehooks";
import { customToastStyle } from "../../redux/api";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Language = () => {
  const methods = useForm();
  const [languageList, setLanguageList] = useState([]);
  const [page, setPage] = useState(1);
  const [languageDialog, setLanguageDialog] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const { filters, setFilters } = useFilter();

  const [getLanguage] = useLazyGetLanguageQuery();
  const [deleteLanguage, { isLoading: isDeleteLanguageLoading }] = useDeleteLanguageMutation();

  const handleAction = (type, item = null) => {
    switch (type) {
      case "OPEN_DELETE":
        setSelectedLanguageId(item);
        setIsDeleteOpen(true);
        break;
      case "CLOSE_DELETE":
        setIsDeleteOpen(false);
        setSelectedLanguageId(null);
        break;
      case "CONFIRM_DELETE":
        if (selectedLanguageId) {
          handleDeleteLanguage(selectedLanguageId);
        }
        setIsDeleteOpen(false);
        break;
      case "OPEN_ADD_LANGUAGE":
        methods.reset();
        setLanguageDialog(true);
        break;
      case "CLOSE_ADD_LANGUAGE":
        setLanguageDialog(false);
        methods.reset();
        break;
      default:
        console.warn("Unknown action type:", type);
    }
  };

  const { columns, rows } = languageTableData(languageList, (item) =>
    handleAction("OPEN_DELETE", item.id)
  );

  const handleChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, name: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  const handleDeleteLanguage = async (id) => {
    try {
      const deleteLanguageResponse = await deleteLanguage(id);
      if (!deleteLanguageResponse?.data?.success) {
        toast.error(`${deleteLanguageResponse?.data?.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });
      }
    } catch (err) {
      console.error("Failed to delete Language:", err);
    }
  };

  useEffect(() => {
    const fetchLanguageData = async () => {
      const languageData = await getLanguage(filters);
      if (languageData?.data?.success) setLanguageList(languageData?.data?.data);
    };
    fetchLanguageData();
  }, [filters, isDeleteLanguageLoading, page]);

  return (
    <FormProvider {...methods}>
      <div className="flex justify-end">
        <Button onClick={() => handleAction("OPEN_ADD_LANGUAGE")} variant="addButton">
          Add Language
        </Button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Language
                </MDTypography>
                <input
                  className="text-sm mr-2 p-2 text-gray-700 rounded-md w-56"
                  type="text"
                  placeholder="Search By Language Name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {languageDialog && (
        <AddLanguage
          title="Add Language"
          open={languageDialog}
          onClose={() => handleAction("CLOSE_ADD_LANGUAGE")}
          setLanguageList={setLanguageList}
        />
      )}
      {isDeleteOpen && (
        <ConfirmDelete
          title=""
          fullMessage="Are you sure you want to delete this language?"
          handleClose={() => handleAction("CLOSE_DELETE")}
          deleteHandler={() => handleAction("CONFIRM_DELETE")}
          open={isDeleteOpen}
        />
      )}
      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={languageList.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </FormProvider>
  );
};

export default Language;
