import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import TextFieldStyled from "../shared/input/TextField"; // Adjust the import based on your file structure

function RHFTextField({
  name,
  helperText,
  placeholder,
  sx,
  required = false,
  defaultValue = "", // Default to an empty string if no default is provided
  pattern,
  disabled = false,
  bgcolor, // Add bgcolor prop
  ...other
}) {
  const { control } = useFormContext();

  // Dynamically set validation rules based on the `required` prop
  const validationRules = required
    ? {
        required: {
          value: required,
          message: `This field is required`,
        },
        pattern: pattern && {
          value: pattern,
          message: `Please enter a valid value`,
        },
        validate: {
          notEmpty: (value) => {
            if (value == null) {
              return "This field cannot be empty or just spaces";
            }
            const stringValue = String(value);
            return stringValue.trim() !== "" || "This field cannot be empty or just spaces";
          },
          noLeadingSpaces: (value) => {
            if (value == null) {
              return true;
            }
            const stringValue = String(value);
            return !stringValue.startsWith(" ") || "Leading spaces are not allowed";
          },
        },
      }
    : {}; // No validation rules if not required

  return (
    <Controller
      name={name}
      control={control}
      rules={validationRules}
      render={({ field, fieldState: { error } }) => (
        <TextFieldStyled
          {...field}
          fullWidth
          value={field.value || defaultValue} // If value is empty, set to defaultValue
          error={!!error}
          helperText={error ? error.message : helperText}
          placeholder={placeholder}
          disabled={disabled}
          bgcolor={bgcolor || "transparent"}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black", // Keep the text color black when disabled
            },
            ...sx,
          }}
          {...other}
        />
      )}
    />
  );
}

export default RHFTextField;
