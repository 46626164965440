import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PanditProfile from "../../../assets/images/panditProfile.jpeg";
import { Switch } from "@mui/material";

export default function authorsTableData(userData, handleToggleChange) {
  const Author = ({ image, name, lastname, dialCode, contact, email }) => (
    <>
      {console.log(image, "image")}
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={image ? image : PanditProfile} name={name} size="lg" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name + " " + lastname || "-"}
          </MDTypography>
          <MDTypography display="block" variant="caption">
            {dialCode + " " + contact || "-"}
          </MDTypography>
          <MDTypography display="block" variant="caption">
            {email || "-"}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );

  const columns = [
    { Header: "User", accessor: "name", align: "left" },
    { Header: "Address", accessor: "address", align: "left" },
    { Header: "Total Orders", accessor: "totalOrders", align: "center" },
    { Header: "Total Amount", accessor: "totalAmount", align: "center" },
    { Header: "Is Active", accessor: "isActive", align: "center" },
  ];

  const rows =
    userData && userData.length > 0
      ? userData.map((item, index) => ({
          name: (
            <Author
              image={item?.profileImage}
              name={item?.firstName || "-"}
              lastname={item?.lastName || "-"}
              dialCode={item?.dialCode || "-"}
              contact={item?.phone || "-"}
              email={item?.email || "-"}
            />
          ),
          address: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item?.address.map((address, idx) => (
                <h1 key={idx}>
                  {address.address}, {address.landMark} || {"-"}
                </h1>
              ))}
            </MDTypography>
          ),
          totalOrders: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              <h1>{item?.orderStats?.totalOrders}</h1>
            </MDTypography>
          ),
          totalAmount: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              <h1>{item?.orderStats?.totalAmount}</h1>
            </MDTypography>
          ),
          isActive: (
            <Switch
              id={`switch-${index}`}
              checked={item?.isActive}
              onChange={() => handleToggleChange(item.id || item._id)}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: item?.isActive ? "green !important" : "red !important",
                  opacity: 1,
                },
                "& .Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "green !important",
                },
                "& .MuiSwitch-thumb": {
                  color: "white",
                },
                "& .MuiSwitch-thumb.Mui-checked": {
                  color: "white",
                },
              }}
            />
          ),
        }))
      : [
          {
            preview: "",
            name: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
              >
                No Data Found
              </MDTypography>
            ),
            address: "",
            language: "",
            isdocumentVarification: "",
            isPlanApproved: "",
            availableTime: "",
            avgRating: "",
          },
        ];

  return {
    columns,
    rows,
  };
}
