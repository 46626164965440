import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "subComponents/Footer";
import ComplexStatisticsCard from "subComponents/Cards/StatisticsCards/ComplexStatisticsCard";
import PendingReq from "layouts/dashboard/components/PendingReq";
import Wallet from "layouts/dashboard/components/WalletTransaction";
import WalletTransactionHistory from "layouts/dashboard/components/WalletTransactionHistory";
import AssignPanditHistory from "layouts/dashboard/components/AssignPanditHistory";
import CurrentYagna from "layouts/dashboard/components/CurrentYagna";
import { useNavigate } from "react-router-dom";
import { useLazyGetDashboardQuery } from "../../redux/api/dashboardApi";
import { getDashboardAction } from "../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import useFilter from "utility/useFilter";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState("currentYagna");
  // const [dashboardDataList, setDashboardDataList] = useState({});
  const refreshDashboard = useSelector((state) => state?.dashboard?.refreshDashboard);
  const [getDashboard, { data: dashboardDataList }] = useLazyGetDashboardQuery();
  const { filters } = useFilter();

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const handleBookingClick = () => {
    navigate("/bookings");
  };

  const handleTransactionClick = () => {
    navigate("/wallet-transactions");
  };

  const handleActivePanditsClick = () => {
    navigate("/pandits");
  };

  // const fetchDashboardData = async () => {
  //   try {
  //     const response = await getDashboard({
  //       sortBy: "createdAt:desc",
  //     }).unwrap();
  //     if (response) {
  //       dispatch(getDashboardAction(response?.data));
  //     }
  //   } catch (err) {}
  // };

  useEffect(() => {
    const fetchDashboardData = async () => {
      const dashboardData = await getDashboard(filters);
      console.log("🚀 ~ fetchDashboardData ~ dashboardData:", dashboardData);
      // if (dashboardData?.data?.success) {
      //   // dispatch(getDashboardAction(dashboardData?.data?.data));
      //   // setDashboardDataList(dashboardData?.data?.data);
      // }
    };
    fetchDashboardData();
  }, [filters, refreshDashboard]);

  return (
    <>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleBookingClick} mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="book"
                title="Total Bookings"
                count={dashboardDataList?.data?.totalBooking ?? 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleBookingClick} mb={1.5}>
              <ComplexStatisticsCard
                icon="pending_actions"
                title="Pending Request"
                count={dashboardDataList?.data?.pendingRequests ?? 0}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleTransactionClick} mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="currency_rupee"
                title="Wallet Transaction"
                count={dashboardDataList?.data?.totalAmount ?? 0}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleActivePanditsClick} mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="groups"
                title="Active Pandits"
                count={dashboardDataList?.data?.totalActivePandits ?? 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleBookingClick} mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="currency_rupee"
                title="Total Amount"
                count={dashboardDataList?.data?.totalAmount ?? 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleBookingClick} mb={1.5}>
              <ComplexStatisticsCard
                icon="currency_rupee"
                title="Admin Amount"
                count={dashboardDataList?.data?.adminAmount ?? 0}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleTransactionClick} mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="currency_rupee"
                title="Pandit Amount"
                count={dashboardDataList?.data?.panditAmount ?? 0}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox onClick={handleActivePanditsClick} mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="groups"
                title="Total Approved Amount"
                count={dashboardDataList?.data?.approvedAmountToPandit?.totalApprovedAmount ?? 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox mt={4.5}></MDBox>
        <MDBox>
          <div className="flex mb-4 border-b-2 w-full ">
            <h1
              onClick={() => handleSectionClick("currentYagna")}
              className={`mr-4 cursor-pointer text-sm border-b-2 ${
                activeSection === "currentYagna"
                  ? "border-customShadow text-orange-600"
                  : "border-none"
              }`}
            >
              Current Yagna
            </h1>
            <h1
              onClick={() => handleSectionClick("pending")}
              className={`mr-4 cursor-pointer text-sm border-b-2 ${
                activeSection === "pending" ? "border-customShadow text-orange-600" : "border-none"
              }`}
            >
              Assigning Pandit
            </h1>
            <h1
              onClick={() => handleSectionClick("wallet")}
              className={`mr-4 cursor-pointer text-sm border-b-2 ${
                activeSection === "wallet" ? "border-customShadow text-orange-600" : "border-none"
              }`}
            >
              Wallet Transaction
            </h1>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8} style={{ height: "500px" }}>
              {activeSection === "currentYagna" && (
                <CurrentYagna yagnaData={dashboardDataList?.data?.recentYagnaOrders} />
              )}
              {activeSection === "pending" && (
                <PendingReq assignData={dashboardDataList?.data?.assigningPandit} />
              )}
              {activeSection === "wallet" && (
                <Wallet walletData={dashboardDataList?.data?.walletTransactionPending} />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {activeSection === "pending" && (
                <AssignPanditHistory
                  assignPanditData={dashboardDataList?.data?.assignmentTracking}
                />
              )}
              {activeSection === "wallet" && (
                <WalletTransactionHistory walletData={dashboardDataList?.data?.walletTransaction} />
              )}
              {/* {activeSection === "currentYagna" && <CurrentYagnaHistory />} */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </>
  );
}

export default Dashboard;
