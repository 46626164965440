import { formatEstimReachTime } from "utility/extractDateTime";
import * as XLSX from "xlsx";

// Utility function to prepare data in the required flat structure
const prepareDataForExport = (data) => {
  return data.map((item, index) => {
    const order = item?.order || {};
    const user = item?.order?.orderedUser || {};
    const pandit = item?.panditUsers[0] || {};
    const panditArrival = item?.panditArrival || {};

    const { formattedDate, formattedTime } = formatEstimReachTime(order?.createdAt);

    return {
      "Sr No.": index + 1,
      "Order ID": item?._id || "N/A",
      "User Name": `${user?.firstName || "N/A"} ${user?.lastName || ""}`,
      "User Number": user?.phone || "N/A",
      "Yajya Name": order?.plan?.name || "N/A",
      "Booking Date and Time": formattedDate + " " + formattedTime || "N/A",
      "Pandit Name": pandit?.user?.firstName + " " + pandit?.user?.lastName || "N/A",
      "Pandit Arrived": panditArrival?.hasArrived ? "Yes" : "No",
      Status: order?.status || "N/A",
      "Booked Pandit": pandit?.status || "N/A",
      "Amount Received": `₹${order?.plan?.totalAmount || "0.00"}`,
      "Amount to be Paid to Pandit": `₹${order?.plan?.amount || "0.00"}`,
    };
  });
};

// Export to CSV
export const exportToCSV = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid data provided for CSV export");
    return;
  }

  const preparedData = prepareDataForExport(data);

  const csvRows = [
    Object.keys(preparedData[0]).join(","), // Header row
    ...preparedData.map((row) =>
      Object.values(row)
        .map((value) => `"${String(value).replace(/"/g, '""')}"`) // Escape quotes
        .join(",")
    ),
  ];

  const csvContent = csvRows.join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Trigger download
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "data.csv";
  a.click();

  // Cleanup
  URL.revokeObjectURL(url);
};

// Export to Excel

export const exportToExcel = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid data provided for Excel export");
    return;
  }

  const preparedData = prepareDataForExport(data);

  // Create Excel worksheet and workbook
  const worksheet = XLSX.utils.json_to_sheet(preparedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Trigger download
  XLSX.writeFile(workbook, "data.xlsx");
};

export const printData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid data provided for printing");
    return;
  }

  const printContent = `
      <table style="width: 100%; border-collapse: collapse; font-family: Arial, sans-serif;">
        <thead>
          <tr style="background-color: #313265; color: #fff; text-align: left;">
            <th style="padding: 10px; border: 1px solid #ccc;">Sr No.</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Order ID</th>
            <th style="padding: 10px; border: 1px solid #ccc;">User Name</th>
            <th style="padding: 10px; border: 1px solid #ccc;">User Number</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Yajya Name</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Booking Date and Time</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Pandit Name</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Pandit Arrived</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Status</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Booked Pandit</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Amount Received</th>
            <th style="padding: 10px; border: 1px solid #ccc;">Amount to be Paid to Pandit</th>
          </tr>
        </thead>
        <tbody>
          ${data
            .map((item, index) => {
              const order = item?.order || {};
              const user = item?.order?.orderedUser || {};
              const pandit = item?.panditUsers.user || {};
              const panditArrival = item?.panditArrival || {};

              const bookingDateTime = order?.createdAt
                ? new Date(order?.createdAt).toLocaleString()
                : "N/A";

              return `
                <tr style="border: 1px solid #ccc;">
                  <td style="padding: 10px; border: 1px solid #ccc;">${index + 1}</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${item?._id || "N/A"}</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${user?.firstName || "N/A"} ${
                user?.lastName || ""
              }</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${user?.phone || "N/A"}</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${
                    order?.plan?.name || "N/A"
                  }</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${bookingDateTime}</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${
                    pandit?.firstName + pandit?.lastName || "N/A"
                  }</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${
                    panditArrival?.hasArrived ? "Yes" : "No"
                  }</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${order?.status || "N/A"}</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">${
                    pandit?.assigned || "N/A"
                  }</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">₹${
                    order?.amount || "0.00"
                  }</td>
                  <td style="padding: 10px; border: 1px solid #ccc;">₹${
                    order?.amountToPayPandit || "0.00"
                  }</td>
                </tr>
              `;
            })
            .join("")}
        </tbody>
      </table>
    `;

  const newWindow = window.open("", "_blank");
  newWindow?.document.write(
    `<html>
        <head>
          <title>Order Details</title>
        </head>
        <body style="padding: 20px; background-color: #f4f4f4;">
          ${printContent}
        </body>
      </html>`
  );
  newWindow?.document.close();
  newWindow?.print();
  newWindow?.close();
};
