import { useEffect } from "react";
import { getToken, isSupported } from "firebase/messaging";
import { messaging } from "./firebaseConfig";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setDeviceIdAction } from "../redux/slices/deviceTokenLocSlice";

const useRequestPermission = () => {
  const dispatch = useDispatch();
  console.log(
    "🚀 ~ requestPermission ~ process.env.REACT_APP_FIREBASE_WEB_PUSH_VAPID_KEY:",
    process.env.REACT_APP_FIREBASE_WEB_PUSH_VAPID_KEY
  );

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const supported = await isSupported();
        console.log("🚀 ~ requestPermission ~ supported:", supported);

        if (supported) {
          // Requesting permission using Notification API
          const permission = await Notification.requestPermission();

          if (permission === "granted") {
            try {
              const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_VAPID_KEY,
              });

              if (token) {
                dispatch(setDeviceIdAction(token));
                localStorage.setItem("deviceId", token);
              }
            } catch (error) {
              console.error("Error generating token: ", error);
            }
          } else {
            dispatch(setDeviceIdAction(null));
            localStorage.removeItem("deviceId");
            if (permission === "denied") {
              toast.error("You denied the notification permission");
            }
          }
        } else {
          console.warn("Firebase Messaging is not supported in this browser.");
        }
      } catch (error) {
        console.error("Error checking Firebase messaging support:", error);
      }
    };

    requestPermission();
  }, [dispatch]);
};

export default useRequestPermission;
