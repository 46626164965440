import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import MDAvatar from "components/MDAvatar";
import PanditProfile from "../../../assets/images/panditProfile.jpeg";
import { Box, Switch } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

export default function authorsTableData(panditsData, handleVerifyClick, handleToggleChange) {
  const navigate = useNavigate();

  const handlePreviewClick = (item) => {
    navigate("/pandits/pandit-profile", { state: { item } });
  };

  const Author = ({ image, name, lastname, dialCode, contact, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image || PanditProfile} name={name} size="lg" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name + " " + lastname || "-"}
        </MDTypography>
        <MDTypography display="block" variant="caption">
          {dialCode + " " + contact || "-"}
        </MDTypography>
        <MDTypography display="block" variant="caption">
          {email || "-"}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const columns = [
    { Header: "", accessor: "preview", align: "left" },
    { Header: "Pandit", accessor: "name", align: "left" },
    { Header: "Address", accessor: "address", align: "left", width: "20%" },
    { Header: "Language", accessor: "language", align: "left" },
    { Header: "Avg Rating", accessor: "avgRating", align: "center" },
    { Header: "Subscribed", accessor: "isPlanApproved", align: "center" },
    { Header: "Availability", accessor: "availability", align: "center" },
    { Header: "Document Verification", accessor: "isdocumentVarification", align: "center" },
    { Header: "Pandit Active", accessor: "isActive", align: "center" },
  ];

  const rows =
    panditsData && panditsData.length > 0
      ? panditsData.map((item, index) => ({
          preview: (
            <Icon
              className="w-5 h-5 text-gray-600"
              onClick={() => handlePreviewClick(item)}
              icon="mdi:eye"
            />
          ),
          name: (
            <Author
              image={item?.profileImage}
              name={item?.firstName || "-"}
              lastname={item?.lastName || "-"}
              dialCode={item?.dialCode || "-"}
              contact={item?.phone || "-"}
              email={item?.email || "-"}
            />
          ),
          address: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item?.address.map((address, idx) => (
                <h1 key={idx}>
                  {address.address}, {address.landMark} || {"-"}
                </h1>
              ))}
            </MDTypography>
          ),
          language: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item?.language.map((lang, idx) => (
                <h1 key={idx}>{lang.name || "-"}</h1>
              ))}
            </MDTypography>
          ),
          isActive: (
            <Switch
              id={`switch-${index}`}
              checked={item?.isActive}
              onChange={() => handleToggleChange(item.id || item._id)}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: item?.isActive ? "green !important" : "red !important",
                  opacity: 1,
                },
                "& .Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "green !important",
                },
                "& .MuiSwitch-thumb": {
                  color: "white",
                },
                "& .MuiSwitch-thumb.Mui-checked": {
                  color: "white",
                },
              }}
            />
          ),

          isdocumentVarification: (
            <Box>
              {item?.isDocumentVerified ? (
                <button
                  onClick={() => handleVerifyClick(index)}
                  className="w-20 text-sm h-8 text-white bg-green-700 rounded-lg"
                >
                  Verified
                </button>
              ) : (
                <button
                  onClick={() => handleVerifyClick(index)}
                  className="w-16 text-sm h-8 text-white bg-red-700 rounded-lg"
                >
                  Verify
                </button>
              )}
            </Box>
          ),
          isPlanApproved: (
            <div
              className={`w-6 h-3 rounded-full ${
                item.isPlanActive ? "bg-green-700" : "bg-red-700"
              }`}
            ></div>
          ),
          availability: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item?.availableTime?.is24x7 ? (
                <div>
                  <span className="text-green-700">Available 24/7</span>
                </div>
              ) : null}

              {/* Unavailable Time Section */}
              {item?.unAvailableTime && (
                <div>
                  <hr style={{ margin: "10px 0", border: "0.5px solid #ccc" }} />{" "}
                  <span className="text-red-500">UnAvailableTime: </span>
                  <span>
                    {item?.unAvailableTime?.date
                      ? new Date(item.unAvailableTime.date).toLocaleDateString()
                      : "-"}{" "}
                    &nbsp;
                    {item?.unAvailableTime?.from || "-"} to {item?.unAvailableTime?.to || "-"}
                  </span>
                </div>
              )}
            </MDTypography>
          ),
          avgRating: (
            <Stack spacing={1}>
              <Rating
                name="half-rating"
                defaultValue={item?.avgRating || 0}
                size="small"
                readOnly
                precision={0.5}
              />
            </Stack>
          ),
        }))
      : [
          {
            preview: "",
            name: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
              >
                No Data Found
              </MDTypography>
            ),
            address: "",
            language: "",
            isdocumentVarification: "",
            isPlanApproved: "",
            availableTime: "",
            avgRating: "",
          },
        ];

  return {
    columns,
    rows,
  };
}
