import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import Card from "@mui/material/Card";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";

import {
  useLazyGetRelevantPanditQuery,
  useAssignPanditMutation,
} from "../../../../../redux/api/bookingApi";
import { useLazyGetDashboardQuery } from "../../../../../redux/api/dashboardApi";
import { refreshDasboardAction } from "../../../../../redux/slices/dashboardSlice";

const AssigningDialog = ({ setIsAssigned, open, orderId }) => {
  const dispatch = useDispatch();

  const [panditList, setPanditList] = useState([]);
  const [fallbackPanditList, setFallbackPanditList] = useState([]);
  const [showFallbackList, setShowFallbackList] = useState(false); // State to show fallback list
  const [selectAll, setSelectAll] = useState(false); // Track select/deselect all
  const [getRelevantPandit] = useLazyGetRelevantPanditQuery();
  const [getDashboard, result] = useLazyGetDashboardQuery();
  const [assignPandit, { isSuccess }] = useAssignPanditMutation();

  const toggleChecked = (index, isChecked) => {
    const updatedFallbackCardsData = fallbackPanditList.map((card, i) =>
      i === index ? { ...card, isChecked } : card
    );
    setFallbackPanditList(updatedFallbackCardsData);
  };

  const handleSelectAllClick = () => {
    // Toggle select/deselect all for both panditList and fallbackPanditList
    const updatedFallbackCardsData = fallbackPanditList.map((card) => ({
      ...card,
      isChecked: !selectAll,
    }));
    setFallbackPanditList(updatedFallbackCardsData);

    setSelectAll(!selectAll); // Toggle selectAll state
  };

  const handleAssignPandit = async () => {
    // Check which list to assign based on the current view
    const selectedPanditId = fallbackPanditList
      .filter((item) => item.isChecked)
      .map((item) => item.id);

    if (selectedPanditId.length === 0) {
      alert("Please select at least one pandit to assign.");
      return;
    }

    const payload = {
      panditUserId: selectedPanditId,
      orderId,
      reason: "Assigning in progress",
    };

    await assignPandit(payload);
    dispatch(refreshDasboardAction());
    // refetch();
    setIsAssigned(false);
  };

  const handleCancelClick = () => {
    setIsAssigned(false);
  };

  const handleShowFallbackClick = (isYes) => {
    if (isYes) {
      setShowFallbackList(true);
    } else {
      handleCancelClick();
    }
  };

  useEffect(() => {
    const fetchPanditData = async () => {
      const panditData = await getRelevantPandit({ orderId });

      if (panditData?.data?.success) {
        setFallbackPanditList(panditData?.data?.data?.fallbackPandits); // Set fallback list

        // setPanditList(panditData?.data?.data?.filteredPandits);

        // if (panditData?.data?.data?.filteredPandits?.length === 0) {
        //   setFallbackPanditList(panditData?.data?.data?.fallbackPandits); // Set fallback list
        // }
      }
    };
    fetchPanditData();
  }, [orderId]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     refetch(); // Refetch dashboard data when the assignment is successful
  //   }
  // }, [isSuccess, refetch]);

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          color: "#FD8D4C",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1>Assign Pandit</h1>
        <span onClick={handleCancelClick} className="text-black mt-1">
          <Icon className="h-6 w-6" icon="ic:baseline-close" />
        </span>
      </DialogTitle>
      <hr />
      <DialogContent sx={{ width: "500px", height: "350px", textAlign: "center" }}>
        {/* Show the fallback message if no filtered pandits */}

        <div className="flex flex-col">
          <div className="flex justify-end overflow-y-scroll">
            <Button onClick={handleSelectAllClick} variant="submit" type="button">
              {selectAll ? "Deselect All" : "Select All"}
            </Button>
          </div>
          {fallbackPanditList &&
            fallbackPanditList?.map((item, index) => (
              <Card
                key={index}
                style={{
                  boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)",
                  marginTop: "20px",
                }}
                className="inset-0 relative p-3"
              >
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={item.profileImage} alt="" className="h-16 w-16 rounded-full" />
                    <div className="ml-4">
                      <div className="flex justify-between">
                        <h5 className="font-bold text-sm">
                          Name :{" "}
                          <span className="font-normal text-sm">
                            {item.firstName}&nbsp;{item.lastName}
                          </span>
                        </h5>
                      </div>
                      <div className="flex">
                        <h5 className="text-sm font-bold">
                          Contact :{" "}
                          <span className="font-normal text-sm">
                            +{item.dialCode}&nbsp;{item.phone}
                          </span>
                        </h5>
                      </div>
                      <div className="flex">
                        <h5 className="text-sm font-bold">
                          Language :{" "}
                          <span className="font-normal text-sm">
                            {item.language && item.language?.length > 0
                              ? item.language.map((lang) => lang.name).join(", ")
                              : "N/A"}
                          </span>
                        </h5>
                      </div>
                      {/* NOTE: Can uncomment if need to show unAvailableTime */}
                      {/* {item?.unAvailableTime && (
                        <div className="flex">
                          <hr style={{ margin: "10px 0", border: "0.5px solid #ccc" }} />{" "}
                          <span className="font-bold text-sm text-red-500">UnAvailableTime : </span>
                          &nbsp;
                          <span className="font-normal text-sm">
                            {item?.unAvailableTime?.date
                              ? new Date(item.unAvailableTime.date).toLocaleDateString()
                              : "-"}{" "}
                            &nbsp;
                            <span className="font-normal text-sm">
                              {item?.unAvailableTime?.from || "-"} to{" "}
                              {item?.unAvailableTime?.to || "-"}
                            </span>
                          </span>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <input
                      onChange={(e) => toggleChecked(index, e.target.checked)}
                      name="checkbox"
                      className="h-5 w-5"
                      type="checkbox"
                      checked={item.isChecked}
                    />
                  </div>
                </div>
              </Card>
            ))}
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          marginTop: "15px",
        }}
      >
        {/* Yes/No Buttons in the bottom-left corner */}
        {/* {!showFallbackList && panditList.length === 0 && (
          <div className="flex space-x-4">
            <Button variant="submit" onClick={() => handleShowFallbackClick(true)}>
              Yes
            </Button>
            <Button
              variant="submit"
              sx={{
                backgroundColor: "#B91C1C",
                "&:hover": {
                  backgroundColor: "#B91C1C",
                },
              }}
              onClick={() => handleShowFallbackClick(false)}
            >
              No
            </Button>
          </div>
        )} */}

        <Button variant="submit" onClick={handleAssignPandit} type="submit">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssigningDialog.propTypes = {
  setIsAssigned: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default AssigningDialog;
