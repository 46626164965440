/**
 * Formats an ISO date string into a readable date and time format.
 * @param {string} isoString - The ISO date string to format (e.g., "2024-12-14T02:30:00.000Z").
 * @returns {Object} - An object containing formatted date and time.
 *                     { formattedDate: "YYYY-MM-DD", formattedTime: "HH:mm hrs" }
 */
export function formatEstimReachTime(isoString) {
  if (!isoString) return { formattedDate: "", formattedTime: "" };

  try {
    const [datePart, timePart] = isoString.split("T");
    const [hours, minutes] = timePart.split(":");

    return {
      formattedDate: datePart,
      formattedTime: `${hours}:${minutes} hrs`,
    };
  } catch (error) {
    console.error("Invalid ISO string format:", isoString);
    return { formattedDate: "", formattedTime: "" };
  }
}
