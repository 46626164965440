import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "./redux/store/store";

import { isSupported } from "firebase/messaging";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);

// Registering the service worker for Firebase Messaging
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      isSupported()
        .then((supported) => {
          if (supported) {
            console.log("Firebase Messaging is supported");
          } else {
            console.warn("Firebase Messaging is not supported in this browser.");
          }
        })
        .catch((error) => {
          console.error("Error checking Firebase messaging support:", error);
        });
    })
    .catch((error) => {
      console.error("Service worker registration failed:", error);
    });
} else {
  console.warn("Service workers are not supported in this browser.");
}
