import MDTypography from "components/MDTypography";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

export default function samagriTableData(samagriList, handleDeleteClick) {
  const navigate = useNavigate();

  const columns = [
    { Header: "category", accessor: "category", align: "left" },
    { Header: "sub-category", accessor: "subCategory", align: "left" },
    { Header: "pandit-samagri", accessor: "panditSamagri", align: "center" },
    { Header: "user-samagri", accessor: "userSamagri", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows =
    samagriList?.results && samagriList.results.length > 0
      ? samagriList.results.map((item, index) => ({
          category: (
            <MDTypography width="" variant="caption" color="text" fontWeight="medium">
              {item?.planCategory?.name || "-"}
            </MDTypography>
          ),
          subCategory: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item?.plan?.name || "-"}
            </MDTypography>
          ),
          panditSamagri: (
            <div className="w-40 h-24 p-2 overflow-y-scroll">
              <div className="flex mb-1 justify-between">
                <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                  Name
                </MDTypography>
                <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                  Quantity
                </MDTypography>
              </div>
              {(item?.panditSamgiri || []).map((data, index) => (
                <div key={index} className="">
                  <div className="flex justify-between">
                    <div className="flex">
                      <h1 className="text-sm font-bold mr-1">{index + 1}.</h1>
                      <MDTypography
                        display="block"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                        {data?.name || "-"}
                      </MDTypography>
                    </div>
                    <MDTypography
                      display="block"
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                    >
                      {data?.quantity || "-"}
                    </MDTypography>
                  </div>
                </div>
              ))}
            </div>
          ),
          userSamagri: (
            <div className="w-40 h-24 p-2 overflow-y-scroll">
              <div className="flex mb-1 justify-between">
                <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                  Name
                </MDTypography>
                <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                  Quantity
                </MDTypography>
              </div>
              {(item?.userSamgiri || []).map((data, index) => (
                <div key={index} className="">
                  <div className="flex justify-between">
                    <div className="flex">
                      <h1 className="text-sm font-bold mr-1">{index + 1}.</h1>
                      <MDTypography
                        display="block"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                        {data?.name || "-"}
                      </MDTypography>
                    </div>
                    <MDTypography
                      display="block"
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                    >
                      {data?.quantity || "-"}
                    </MDTypography>
                  </div>
                </div>
              ))}
            </div>
          ),
          action: (
            <MDTypography className="flex">
              <Icon
                className="text-black mr-1"
                icon="ep:edit"
                onClick={() => navigate("/samagri/edit-samagri", { state: { item } })}
              />
              <Icon
                className="text-red-800"
                icon="ep:delete"
                onClick={() => handleDeleteClick(item)}
              />
            </MDTypography>
          ),
        }))
      : [
          {
            category: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
              >
                No Data Found
              </MDTypography>
            ),
            subCategory: "",
            panditSamagri: "",
            userSamagri: "",
            action: "",
          },
        ];

  return {
    columns,
    rows,
  };
}
