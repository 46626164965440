import io from "socket.io-client";
import toast from "react-hot-toast";

import URLS from "../redux/constants/api";

const SOCKET_URL = URLS.HOST_URL;

let socket;

export const connectSocket = (token, deviceId) => {
  if (!socket) {
    socket = io(SOCKET_URL, {
      auth: {
        token,
        deviceId,
      },
    });

    socket.on("connect", () => {
      console.log("Connected to socket");
    });

    socket.on("connection_response", (data) => {});

    socket.on("orderCreated", (order) => {
      toast.success("New Yagna booked");
    });

    socket.on("disconnect", () => {});
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};
