import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "subComponents/Tables/DataTable";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import assignPanditTableData from "./data/assignPanditTableData";
import useFetchData from "../../utility/useFetchData";
import { useLazyGetBookingQuery } from "../../redux/api/bookingApi";
import { getBookingAction } from "../../redux/slices/bookingSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import useFilter from "utility/useFilter";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import Filter from "layouts/filter/filter";

function AssignPandit() {
  const [getBooking] = useLazyGetBookingQuery();
  const [page, setPage] = useState(1);
  // const bookingData = useSelector((state) => state?.booking?.booking);
  const [bookingList, setBookingList] = useState();
  // const fetchBookingData = useFetchData(getBooking, getBookingAction);
  // const { columns, rows } = authorsTableData(bookingData);
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const { filters, setFilters } = useFilter();
  // useEffect(() => {
  //   fetchBookingData({ sortBy: "createdAt:desc" });
  // }, [bookingData]);
  const handleChange = (e, p) => {
    setPage(p);
  };
  useEffect(() => {
    const fetchBookingData = async () => {
      const bookingData = await getBooking(filters);
      if (bookingData?.data?.success) setBookingList(bookingData?.data?.data);
    };
    fetchBookingData();
  }, [filters, page]);
  const { columns, rows } = assignPanditTableData(bookingList);

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, search: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  console.log(bookingList, "bookingList");

  return (
    <div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Bookings
                </MDTypography>
                <input
                  className="text-sm text-gray-700 p-2 rounded-md w-56"
                  type="text"
                  placeholder="Search By User/Pandit"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </MDBox>
              <Box mx={2}>
                <Filter data={bookingList?.results} />
              </Box>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={bookingList?.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </div>
  );
}

export default AssignPandit;
